<template>
  <edit-template @confirm="handleSubmit" :confirmLoading="loading">
    <el-form class="pl-5 pt-2" ref="form" :model="formData" :rules="rules" label-position="left" label-width="100rem" >
      <template v-if="id">
        <el-form-item label="参考城市">{{ info.city_name }}</el-form-item>
        <el-form-item label="入学年份">{{ formData.year }}</el-form-item>
      </template>
      <template v-else>
        <el-form-item label="参考城市" prop="city">
          <el-cascader v-model="formData.city" :options="areaOptions"></el-cascader>
        </el-form-item>
        <el-form-item label="入学年份" prop="year">
          <el-select v-model="formData.year">
            <el-option v-for="i in yearOptions" :key="i.value" :label="i.label" :value="i.value" />
          </el-select>
        </el-form-item>
      </template>
      <div class="z-no-margin-bottom">
        <div class="z-dynamic-list-item" v-for="(domain, index) in formData.examination" :key="domain.id">
          <div class="z-dynamic-list-inner">
            <el-form-item label="考试名称" :prop="`examination.${index}.exam_name`" :rules="dynamicInput">
              <el-input v-model="domain.exam_name" maxlength="30" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item class="ml-2" label="考试满分" :prop="`examination.${index}.score`" :rules="dynamicNumber">
              <el-input v-model="domain.score" type="number" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <el-form-item label-width="40rem">
            <el-button icon="el-icon-delete" v-if="formData.examination.length !== 1" circle @click.prevent="handelRemove(domain)"></el-button>
            <el-button icon="el-icon-plus" circle v-if="formData.examination.length !== maxlength && index === formData.examination.length - 1" @click="handleAdd"></el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </edit-template>
</template>

<script>
import { objectIsEmpty, transformAddOrEditData } from '@/utils'
import { validateNumber } from '@/utils/validate'
import { getAreaDataMixin } from '@/utils/mixins'
import { zsPost, zsGet, getAdjacentYears } from '@/api'
export default {
	_config:{"route":[{"path":"edit","meta":{"title":"编辑"}}, {"path":"add","meta":{"title":"新增"}}]},
  mixins: [getAreaDataMixin],
  data() {
    return {
      id: '',
      yearOptions: [],
      loading: false,
      maxlength: 60,
      dynamicInput: { required: true, message: '请输入', trigger: 'blur' },
      dynamicNumber: { required: true, validator: (_, value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true }), trigger: 'blur' },
      formData: { city: '', year: '', examination: [] },
      rules: {
        city: [{ required: true, message: '请选择参考城市', trigger: 'change' }],
        year: [{ required: true, message: '请选择入学年份', trigger: 'change' }]
      },
      info: {
        city_name: ''
      }
    }
  },
  created() {
    if(this.$route.query.id) {
      this.id = +this.$route.query.id
      this.getConfig()
    } else {
      this.getConfig()
    }
    this.getAreaData()
    this.getData()
  },
  methods: {
    getConfig() {
      getAdjacentYears().then(res => {
        this.yearOptions = res
      })
    },
    getData() {
      if(!this.id) return this.formData.examination.push({ id: Date.now(), exam_name: '', score: '', isAdd: 1 })
      zsGet('/recruit-2024/exam/get-diag-name-conf', { id: this.id }).then(res => {
        const { city_name, year, city_code, diag_name_conf = [] } = res
        this.info.city_name = city_name
        this.formData.city = ['', city_code]
        this.formData.year = year
        const list = diag_name_conf.map(i => ({ id: i.id, exam_name: i.exam_name, score: i.score }))
        this.formData.examination = list
        this.dynamicData.examination = list
      })
    },

    handleAdd() {
      if(this.maxlength === this.formData.examination.length) {
        return this.$message.warning(`最多只能添加${this.maxlength}条`);
      }
      this.formData.examination.push({ id: Date.now(), exam_name: '', score: '', isAdd: 1 })
    },
    handleDelete(item) {
      const index = this.formData.examination.indexOf(item)
      if (!~index) return
      this.formData.examination.splice(index, 1);
    },
    handelRemove(item) {
      const { id, ...other } = item
      if(objectIsEmpty(other, ['isAdd'])) return this.handleDelete(item)
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, _instance, done) => {
          if (action === 'confirm') this.handleDelete(item)
          done()
        }
      }).then(() => {
        this.$message({ type: 'success', message: `删除成功!` })
      })

    },
    handleSubmit() {
      this.$refs['form'].validate(valid => {
        if(valid) {
          const { examination, city, year } = this.formData
          const data = { city_code: city[1], year, diag_name_conf: transformAddOrEditData(examination) }
          if(this.id) data.id = this.id
          const api = `/recruit-2024/exam/${this.id ? 'edit' : 'add'}-diag-name-conf`
          this.loading = true
          zsPost(api, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '新增'}成功`)
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
